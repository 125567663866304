<template>
  <div>
    <v-dialog 
      persistent 
      v-model="dialog" 
      max-width="700px" 
      transition="dialog-transition" 
      scrollable
    >
      <v-card>
        <v-toolbar color="primary">
          <span class="subtitle white--text">Solicitar nueva certificación</span>
        </v-toolbar>
        <v-card-text v-if="!consulta">
           <div class="text-center py-10">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
           </div>
        </v-card-text >
        <v-card-text v-else class="pb-0" >
          <v-stepper v-model="e1" class="elevation-0">

            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="e1 > 1" step="1">CONSULTA</v-stepper-step>
              <v-stepper-step :complete="e1 > 2" step="2">SOLICITUD</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="pa-2">
                <v-card>
                <v-card-text class="text-lg-center text-md-center text-xs-center">
                  <div class="mb-5 font-weight-bold subtitle-1">¿Necesita el certificado para alguna Convocatoria del Órgano Judicial, Consejo de la Magistratura o Fiscalía?</div>
                  <v-btn text class="mr-5 subtitle-1 font-weight-bold" @click="siguiente(true)" large>NO</v-btn>
                  <v-btn text color="primary" class="subtitle-1 font-weight-bold" @click="siguiente(false)" large>SI</v-btn>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn text color="error" @click="$emit('cancelar')">Cancelar</v-btn>
                </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2" class="pa-2">
                <v-card>
                  <v-card-text>
                    <v-form ref="formNuevaCertificacion" v-model="valid" lazy-validation>
                      <v-layout row wrap>
                        <v-flex class="lg12 md12 xs12" v-if="!certificacionNormal">
                          <v-select
                            v-model="certificacion.convocatoria"
                            label="Convocatoria"
                            :items="selectConvocatorias"
                            :rules="[v => (!!v) || 'Debe elegir al menos una opcion']"
                            clearable
                            placeholder="Seleccione una convocatoria"
                            class="required px-1"
                          >
                            <template v-slot:selection="{ item }">                              
                              <div class="d-inline my-2 item-select" >{{ item.text }}</div>
                            </template>
                          </v-select>
                        </v-flex>
                        <v-flex class="lg6 md6 xs12 px-1">
                          <v-select
                            v-model="certificacion.departamento"
                            label="Departamento"
                            :items="$store.state.session.parametros.departamentos"
                            :rules="[v => (!!v) || 'Debe elegir al menos una opcion']"
                            clearable
                            placeholder="Seleccione un departamento"
                            class="required px-1"
                          >
                          </v-select>
                        </v-flex>
                         <v-flex class="lg6 md6 xs12 px-1" v-if="certificacionNormal">
                          <v-select
                            v-model="certificacion.tipo"
                            label="Tipo de certificación"
                            :items="tiposCertificaciones"
                            :rules="[v => (!!v) || 'Debe elegir al menos una opcion']"
                            clearable
                            placeholder="Seleccione un tipo de certificado"
                            class="required px-1"
                            @change="actualizacionTipo"
                          >
                          </v-select>
                        </v-flex>
                        <v-flex class="lg12 md12 xs12 px-1" v-if="certificacionNormal && certificacion.tipo===5">
                          <v-select
                            v-model="certificacion.sociedadCivil"
                            label="Sociedad civil"
                            :items="sociedadesCiviles"
                            :rules="[v => (!!v) || 'Debe elegir al menos una opcion']"
                            clearable
                            placeholder="Seleccione una sociedad civil"
                            class="required px-1"
                            item-text="nombreSociedad"
                            item-value="idSociedadCivil"
                          >
                            <template v-slot:selection="{ item }">                              
                              <div class="d-inline my-2 item-select" >{{ item.nombreSociedad }}</div>
                            </template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-form>
                    <v-alert
                      :value="alert.show"
                      :color="alert.color"
                      dark
                      outlined
                      border="left"
                      :icon="alert.icon"
                      transition="scale-transition"
                      >
                      {{alert.mensaje}}
                    </v-alert>
                    <!--
                    <template v-if="certificacionesEmitidas.length>0">
                        <v-list two-line subheader >
                     <v-list-item
                        v-for="(item,index) in certificacionesEmitidas"
                        :key="index"
                        avatar
                        @click="descargarArchivo(item)"
                      >
                        <v-list-item-avatar >
                          <v-icon color="red">mdi-file-pdf-box</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.titulo }}</v-list-item-title>
                          <v-list-item-subtitle>Presiona para descargar</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                        </v-list>
                    </template> -->
                  </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn  v-if="aceptar" color="default" @click="cancelarRegistro">aceptar</v-btn>
                  <template v-else>
                    <v-btn text color="error" @click="$emit('cancelar')">cancelar</v-btn>
                    <v-btn
                      color="primary"
                      @click="registrarCertificacion"
                      :loading="loading"
                    >
                        GENERAR
                    </v-btn>
                  </template>
                </v-card-actions>
                </v-card>
              </v-stepper-content>

            </v-stepper-items>

            </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const alertDefault={
  show:false,
  color:'success',
  icon:'mdi-check-all',
  mensaje:'Certificación(es) solicitada(as) correctamente, debes realizar el pago para que puedas descargarlas'
}

export default {
  props:{
    dialog:{
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      consulta:false,
      valid: true,
      edit: true,
      loading:false,
      aceptar:false,
      alert:Object.assign({},alertDefault),
      certificacion: {
        tipo: null,
        sociedadCivil:null
      },
      e1:1,
      certificacionNormal: false,
      convocatorias:[],
      selectConvocatorias:[],
      tiposCertificaciones:[],
      sociedadesCiviles:[],
    };
  },
  async created(){
    await this.getTiposCertificaciones()
    await this.getConvocatorias()
    this.consulta = true
    
  },
  methods: {
   async getConvocatorias(){
      const respuesta = await this.$http.get(`${this.$apiUrl}abogado-ciudadano/convocatorias`)
      this.convocatorias=respuesta.data.data
      this.convocatorias.forEach(element=>{
        this.selectConvocatorias.unshift({text:element.titulo,value:element.id})
      })
      if(this.convocatorias.length===0){
        this.siguiente(true)
      }
    },
    async getTiposCertificaciones(){
      const respuesta = await this.$http.get(`${this.$apiUrl}abogado-ciudadano/tipos-certificado`)
      if(respuesta.data){
        this.tiposCertificaciones = [...respuesta.data.data.tipoCertificadosHabilitados]
      }
    },
    registrarCertificacion() {
      if (this.$refs.formNuevaCertificacion.validate()) {
          this.loading=true
          this.edit=false
          if(this.certificacionNormal){
               this.$http
                .post(`${this.$apiUrl}abogado-ciudadano/certificaciones`,this.certificacion)
                .then(response => {
                  //this.descargarArchivo(response.data.data);
                  //this.certificacionesEmitidas[0]=response.data.data
                  if(response){
                    this.aceptar=true;
                    this.alert.show=true
                  }
                }).catch(error=>{
                  this.alert.mensaje=error.response.data.mensaje||'Ocurrio un error al solicitar el certificado, intentalo de nuevo o contactate con el RPA'
                  this.alert.color='error',
                  this.alert.icon ='mdi-file-remove' 
                  this.alert.show=true
                  this.aceptar=true
                });
          }else{
             this.$http
              .post(`${this.$apiUrl}abogado-ciudadano/convocatoria/${this.certificacion.convocatoria}/certificaciones`,{
                departamento:this.certificacion.departamento
              })
              .then(response => {
                this.certificacionesEmitidas=response.data.data
                this.aceptar=true;
                this.alert.show=true
              }).catch(error=>{
                this.alert.mensaje=error.response.data.mensaje 
                this.alert.color='error',
                this.alert.icon ='mdi-file-remove' 
                this.alert.show=true
                this.aceptar=true
              });
          }
      }
    },
    cancelarRegistro() {
      this.$emit("nuevo")
      this.$refs.formNuevaCertificacion.reset();
      this.loading=false
      this.aceptar=false;
      this.edit=true
      this.alert=Object.assign({},alertDefault)
    },
    siguiente(value){
      this.certificacionNormal=value
      this.e1=2
    },
    actualizacionTipo(value){
      if(value===5){
        this.$http.get(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles`).then(response=>{
          if(response){
            const data = response.data.data
            this.sociedadesCiviles = data.filter(e=>e.esRepresentante)
          }
        })
      }
    }
  },
};
</script>
<style>
.required label::after {
  content: " *";
  color: red;
}
.item-select{
  max-width: 95%;
}
</style>
