<template>
  <v-card>
    <v-toolbar color="grey lighten-2"  elevation="0" height="60">
      <div class="subtitle-1 font-weight-medium primary--text">1.- Lista de certificaciones</div>
      <v-spacer></v-spacer>
      <div class="text-right subtitle-2 grey--text">TOTAL {{desserts.length}}</div>
    </v-toolbar>
    <v-card-text>
      <NuevaCertificacion v-if="dialog" :dialog="dialog" @cancelar="dialog=false" @nuevo="actualizarCertificaciones"/>
      <v-row v-if="registrosPendientesPago.length>0">
        <v-col cols="12" class="pb-0" v-for="registro in registrosPendientesPago" :key="registro.id">
          <v-alert color="primary" outlined :value="true" text class="body-2 mb-0">
            Usted tiene pendiente una solicitud de pago mediante la <b>PASARELA DE PAGOS</b> por solicitud de emision de certificado de <b>tipo {{registro.tipoCertificacion}}</b>, 
            de fecha <b>{{registro.fechaCreacion}}</b> <v-btn color="primary" rounded small class="mx-2" @click="realizarPago(registro)">presiona aquí</v-btn> para realizar el pago o puedes 
            <v-btn color="error" rounded small class="mx-2" @click="showConfirmacion('cancelar',registro)">cancelar solicitud</v-btn>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn 
            color="primary lighten-3" 
            @click="dialog=true" 
            class="mr-3" 
            small
          >
            <v-icon class="pr-2" small>mdi-file-plus</v-icon>SOLICITAR CERTIFICACIÓN
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="grey" rounded small dark v-bind="attrs" v-on="on" @click="actualizarCertificaciones">    
                  <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Actualizar lista</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="desserts"
            :calculate-widths="true"
            :mobile-breakpoint="100"
            :loading="loadingTable"
            loading-text="Cargando Datos"
          >
              <template v-slot:item.acciones="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="item.estado==='EMITIDO' && verificarValidez(item.fechaFinValidez)" color="error" icon v-bind="attrs" v-on="on" @click="descargarPdf(item)">
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar certificado</span>
                </v-tooltip>
                 <v-tooltip bottom v-if="item.estado==='SOLICITADO'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="error" icon v-bind="attrs" v-on="on" @click="showConfirmacion('cancelar',item)">
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Cancelar solicitud</span>
                </v-tooltip>
              </template>
              <template v-slot:item.estado="{item}">
                <v-chip :color="getColor(item.estado)">{{item.estado}}</v-chip>
              </template>
              <template v-slot:item.fechaCertificacion="{item}">
                {{formatDateText(item.fechaCertificacion)}}
              </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-dialog
        v-if="dialogPago"
        v-model="dialogPago"
        scrollable  
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar color="secondary" dark>
             Informacion del pago
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogPago=false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="pt-3">
            <codigo-pago 
              v-if="metodoPago.cpt!== null" 
              :cpt="metodoPago.cpt" 
              :center="true"
            />
            <qr-pago 
              v-if="metodoPago.qr!== null" 
              :qr="metodoPago.qr" 
              :center="true"
              :vertical="true"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <Confirmacion
        :pregunta="confirmacion.consultaDialog"
        :consulta="confirmacion.preguntaConsulta"
        :loading="confirmacion.loadingPregunta"
        @si="confirmacionAceptada"
        @no="confirmacion.consultaDialog=false"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import Mabogado from "@/mixins/abogado";
import Mregistro from "@/mixins/registro"
import mixinDateTime from "@/mixins/datetime"
import NuevaCertificacion from "@/components/NuevaCertificacion"
import Confirmacion from "@/common/util/Confirmacion";
export default {
  mixins: [Mabogado, mixinDateTime,Mregistro],
  components:{
    NuevaCertificacion,
    Confirmacion,
    'codigo-pago':()=>import("@/components/Cpt"),
    'qr-pago':()=>import("@/components/QrPago")
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Acciones",
          align: "left",
          value: "acciones",
          sortable: false
        },
        {
          text: "Código",
          align: "left",
          value: "cite",
          sortable: false
        },
        {
          text: "Fecha",
          value: "fechaCertificacion",
          sortable: false
        },
        {
          text: "Estado",
          value: "estado",
          sortable: false
        },
        {
          text: "Tipo",
          value: "tipoCertificacion",
          sortable: false
        },
        {
          text: "solicitante",
          value: "solicitante",
          sortable: false,
          width: "60%"
        }
      ],
      desserts: [],
      dialog:false,
      registrosPendientesPago:[],
      dialogPago:false,
      metodoPago:null,
      confirmacion: {
        consultaDialog: false,
        preguntaConsulta: "",
        tipoConsulta: null,
        loadingPregunta: false,
        model:null
      }
    };
  },
  mounted() {
    if (this.isRegistered) {
      this.getCertificaciones();
    }
  },
  watch: {
    isRegistered(value) {
      if (value) {
        this.getCertificaciones();
      }
    }
  },
  methods:{
    actualizarCertificaciones(){
      this.registrosPendientesPago=[]
      this.dialog = false
      this.getCertificaciones()
      this.getSolicitudPasarelaPago()
    },
    descargarPdf(certificacion){
      this.$store.commit('setMessageOverlay', 'Generando pdf, espere porfavor..')
      this.$store.commit('setOverlay', true);
       this.$http.get(`${this.$apiUrl}abogado-ciudadano/certificaciones/${certificacion.id}/pdf`,{
         responseType: 'blob' 
       }).then(response=>{
        const headers = response.headers;
        const filename = headers['content-disposition'] && headers['content-disposition'].split('filename=')[1].replace(/"/g, '') ||'archivo.pdf';
        const enlace = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        enlace.href = url;
        enlace.setAttribute('download', filename);
        document.body.appendChild(enlace);
        enlace.click();
        document.body.removeChild(enlace); 
        setTimeout(() => {
              URL.revokeObjectURL(url);
          }, 1500);
        this.$store.commit('setOverlay', false);
      }).catch(error=>{
          this.$store.commit('setMessageOverlay', error?.data?.mensaje|| error.message)
          setTimeout(()=>{
            this.$store.commit('setOverlay', false);
          },2000)
      })
    },
    getColor(estado){
      const estados = {
        EMITIDO:'success',
        SOLICITADO:'grey-1'
      }
      return estados[estado]||'error'
    },
    realizarPago(certificacion){
      this.$store.commit('setMessageOverlay', 'Obteniendo informacion de pago, espere porfavor..')
      this.$store.commit('setOverlay', true);
      this.$http.get(`${this.$apiUrl}abogado-ciudadano/certificaciones/${certificacion.id}/informacion-pago`).then(response=>{
        if(response){
          this.$store.commit('setOverlay', false);
          const data = response.data.data 
          if(data.estado!=='EN_PROCESO'){
             window.location.href = data.urlRedireccion
          }else{
            this.dialogPago = true
            this.metodoPago = data
          }
        }
      }).catch(error=>{
          this.$store.commit('setMessageOverlay', error?.data?.mensaje|| error.message)
          setTimeout(()=>{
            this.$store.commit('setOverlay', false);
          },2000)
      })
    },
    verificarValidez(fecha){
        if(!fecha)
          return false
        const fechaActual = new Date()
        const fechaVencimiento = new Date(`${fecha} 23:59:59`)
        return fechaActual<fechaVencimiento
    },
    showConfirmacion(tipo, data = null) {
      this.confirmacion.tipoConsulta = tipo;
      if (tipo == "cancelar") {
        this.confirmacion.preguntaConsulta = `¿Estas seguro de <b class="error--text">CANCELAR</b> la solicitud de certificacion de tipo <b>${data.tipoCertificacion}</b>?.`;
      }
      if(data){
        this.confirmacion.model = JSON.parse(JSON.stringify(data))
      }
      this.confirmacion.consultaDialog = true;
    },
    confirmacionAceptada() {
      if (this.confirmacion.tipoConsulta == "cancelar") {
        this.cancelarSolicitud(this.confirmacion.model);
      }
    },
    cancelarSolicitud(certificacion){
        this.confirmacion.loadingPregunta = true;
        this.$http.put(`${this.$apiUrl}abogado-ciudadano/certificaciones/${certificacion.id}/cancelar`).then(response=>{
          if(response){
            if(response.data.data){
              this.actualizarCertificaciones()
             
            }
            this.confirmacion.loadingPregunta = false;
            this.confirmacion.consultaDialog = false;
          }
        }).catch(e =>{
          this.confirmacion.loadingPregunta = false;
        })
    }
  }
};
</script>