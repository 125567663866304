export default {
    methods: {
        getFormacionAbogado() {  
            this.loadingTable=true;
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/formacion`)
                .then(response => {
                    this.$store.commit("abogado/setFormacion", response.data.data.items);
                    this.loadingTable=false;

                })
        },
        getExperienciaAbogado() {
            this.loadingTable=true;
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/experiencia`)
                .then(response => {
                    this.$store.commit("abogado/setExperiencia", response.data.data.items);
                    this.loadingTable=false;

                })
        },
        getCausasAbogado() {
            this.loadingTable=true;
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/causa`)
                .then(response => {
                    //this.$store.commit("setCausa", response.data.data.items);
                    this.desserts=response.data.data.items;
                    this.loadingTable=false;
                })
        },
        getDocenciaAbogado(){
            this.loadingTable=true;
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/docencias`)
                .then(response => {
                    this.$store.commit("abogado/setDocencia", response.data.data.items);
                    this.loadingTable=false;
                })
        },
        getProduccionIntelectualAbogado(){
            this.loadingTable=true;
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/producciones-intelectuales`)
                .then(response => {
                    this.$store.commit("abogado/setProduccionIntelectual", response.data.data.items);
                    this.loadingTable=false;
                })
        },
        getCertificaciones(){
            this.loadingTable=true;
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/certificaciones`)
                .then(response => {
                    if(response.data.data.total_count>0){
                        this.desserts=response.data.data.items
                        this.registrosPendientesPago = this.desserts.filter(e=>e.estado==='SOLICITADO' && e.metodoPago!=='GRATUITO')
                    }
                    this.loadingTable=false;
                })
        },
        deleteFormacion(id){        
                this.$http.delete(`${this.$apiUrl}abogado-ciudadano/formacion/${id}`)
                .then(response => {
                    this.$store.commit('abogado/removeFormacion',response.data.data);
                    this.loadingPregunta = false;
                    this.consultaDialog = false;  
                }).catch(error=>{
                    console.log(error)
                    this.loadingPregunta = false;
                })         
        },
        deleteExperiencia(id){        
            this.$http.delete(`${this.$apiUrl}abogado-ciudadano/experiencia/${id}`)
            .then(response => {
                this.$store.commit('abogado/removeExperiencia',response.data.data);
                this.loadingPregunta = false;
                this.consultaDialog = false;  
            }).catch(error=>{
                console.log(error)
                this.loadingPregunta = false;
            })           
        },
        getSancionesAbogado(){
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/sanciones`)
            .then(response => {
                if(response.data.data.length>0){
                    this.desserts=response.data.data
                }else{
                    this.$notify({
                        group:'foo',
                        type:'success',
                        title:'IMPORTANTE',
                        text:'FELICIDADES Usted no tiene Ninguna Sancion Registrada'
                    })
                }
                this.loadingTable=false;
            })
        },
        getSociedadesCiviles(){
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles`)
            .then(response => {
                if(response.data.data.length>0){
                    this.desserts=response.data.data
                    this.desserts.forEach(element => {
                        if(element.estado == 'NUEVO'){
                            this.getSolicitudPasarelaPago(element)
                            return
                        }
                    });
                }
                this.loadingTable=false;
            })
        }
    }
}